#form-file-upload {
  width: 100%;
  text-align: center;
  position: relative;
}

.label-file-upload {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px dashed #8daeff; */
  border-radius: 10px;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}


.q-nav > div:first-child > div {
  left: 0;
}