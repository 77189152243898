:root {
  --blue-dark: #1f2c56;
  --blue-light-300: #c6d6ff;
}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

.site-content {
  flex: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img,
a {
  user-select: none;
  -webkit-user-drag: none;
}

.type-dark.place-top {
  background-color: var(--blue-light-300);
  padding: 11px 12px;
  font-size: 12px;
  color: var(--blue-dark);
}

.type-dark.place-top::after {
  border-top-color: var(--blue-light-300) !important;
}

.type-dark.place-top span {
  padding: 0;
  text-align: left;
}

.animated-line {
  width: 1px;
  height: 0;
  background: #1f2c56;
  animation: line-down 3s ease-in-out forwards;
  position: absolute;
  left: 0;
  top: 0;
}

.noScrollBar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.noScrollBar::-webkit-scrollbar {
  display: none;
}

@keyframes line-down {
  to {
    height: 100%;
  }
}


.dropdownMenu {
  transform: translateX(calc(-50% + 20px));
}

@media (min-width: 640px) {
  .avatarIcon:hover .dropdownMenu  {
    display: block;
  }
}


.wysiwig p {
  margin-bottom: 16px;
}

.wysiwig a {
  text-decoration: underline;
}

.wysiwig ol {
  list-style: decimal;
  padding-left: 25px;
}

.wysiwig .list-contents ol {
  padding-left: 16px;
  counter-reset: item;
}

.wysiwig .list-contents ol li {
  display: block;
}

.wysiwig .list-contents ol ol {
  padding-left: 20px;
}

.wysiwig .list-contents ol li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}


.wysiwig ul {
  list-style: disc;
  padding-left: 40px;
  margin-bottom: 24px;
}

.wysiwig h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 16px;
}

.wysiwig table {
  margin-bottom: 36px;
}

.wysiwig table td {
  padding: 0.75rem;
  border: 1px solid white;
}


.mute {
    width: 20px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 4px;
}